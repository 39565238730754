
export default {
    name: 'Logo',

    props: {
        /**
         * Определяет положение логотипа в футере
         */
        isFooter: {
            type: Boolean,
            default: false,
        },

        /**
         * Определяет видимость логотипа партнера (реверсивно)
         */
        hidePartner: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        deviceIsMobile() {
            return this.$deviceIs.mobile;
        },

        collaborationIconSize() {
            return this.deviceIsMobile ? 'small' : 'medium';
        },

        classList() {
            return {
                [this.$style._footer]: this.isFooter,
            };
        },
    },
};
